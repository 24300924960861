import Ready from '@/utils/ready';

const selector = '[data-ref="inputNumber"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/inputNumber').then((InputNumber: any) => {
      new InputNumber.default(element);
    });
  });
})();
