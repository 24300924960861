import Ready from '@/utils/ready';

const selector = '[data-ref="navigation"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/navigation').then((Navigation: any) => {
      new Navigation.default(element);
    });
  });
})();
