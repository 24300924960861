import Ready from '@/utils/ready';

const selector = '[data-ref="map"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/map').then((Map: any) => {
      new Map.default(element);
    });
  });
})();
