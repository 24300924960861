import Ready from '@/utils/ready';

const selector = '#contact';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/contact').then((Contact: any) => {
      new Contact.default(element);
    });
  });
})();
