import Ready from '@/utils/ready';
import 'objectFitPolyfill';

const selector = '[data-object-fit-video]';

const ObjectFitVideo = (el = null) => {
  objectFitPolyfill(el);
};

(() => {
  Ready.watch(selector, (element) => {
    if (element) {
      ObjectFitVideo(element);
    }
  });
})();

export default ObjectFitVideo;
