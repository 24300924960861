import Ready from '@/utils/ready';

const selector = '[data-ref="modal"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/modal').then((Modal: any) => {
      new Modal.default(element);
    });
  });
})();
