import Ready from '@/utils/ready';

const selector = '[data-vc="quote"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/components/organisms/quote').then((Module: any) => {
      new Module.default(element);
    });
  });
})();
