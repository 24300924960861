import Ready from '@/utils/ready';

const selector = '[data-ref="homeVideo"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/homeVideo').then((HomeVideo: any) => {
      new HomeVideo.default(element);
    });
  });
})();
