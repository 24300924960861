import Ready from '@/utils/ready';

const selector = '[data-ref="cartProductsCounter"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/cartProductsCounter').then((CartProductsCounter: any) => {
      new CartProductsCounter.default(element);
    });
  });
})();
