import Ready from '@/utils/ready';

const selector = '[data-vc="price_request"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/components/organisms/priceRequest').then((Module: any) => {
      new Module.default(element);
    });
  });
})();
