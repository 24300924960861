const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
};

const units = {
  base: '100px',
};

const pristineConfig = {
  screens,
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  gutters: {
    xl: '70px',
    lg: '50px',
    md: '30px',
    base: '15px',
    sm: '10px',
    xs: '7.5px',
    none: '0',
  },
  colors: {
    primary: '#9d1936',
    secondary: '#1b2240',
    tertiary: '#ee7164',
    quaternary: '#dfae5f',
    quinary: '#452a19',
    grey: '#9b8c81',
    darkGrey: '#514b43',
    offWhite: '#f7f7f7',
    success: '#649e29',
  },
  spacing: {
    xl4: '100px',
    xl3: '75px',
    xl2: '50px',
    xl: '40px',
    lg: '30px',
    md: '25px',
    base: '20px',
    sm: '15px',
    xs: '10px',
    xs2: '7px',
    xs3: '5px',
    none: '0',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {},
  borderRadius: {
    base: '20px',
    sm: '10px',
  },
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    avenir: ['Avenir LT Std', 'sans-serif'],
    roboto: ['Roboto', 'sans-serif'],
    mulish: ['Mulish', 'sans-serif'],
  },
  fontSize: {
    xl4: '48.83px',
    xl3: '39.06px',
    xl2: '31.25px',
    xl: '25px',
    lg: '20px',
    md: '18px',
    base: '16px',
    sm: '14px',
    xs: '12px',
    xs2: '10px',
    xs3: '8px',
  },
  fontWeight: {},
  height: {
    ...units,
    nav: {
      base: '120px',
      sm: '80px',
    },
    cardThumbnail: {
      timeline: '135px',
    },
  },
  inset: {},
  letterSpacing: {
    xl: '2px',
    lg: '1px',
    md: '0.5px',
    base: '0.15px',
  },
  lineHeight: {},
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  transition: {
    card: 'all 0.5s ease-out',
    base: 'all 0.25s ease-in-out',
  },
  width: {
    ...units,
  },
  zIndex: {},
};

module.exports = pristineConfig;
