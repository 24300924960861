import Ready from '@/utils/ready';

const selector = '[data-ref="select"]';

(() => {
  Ready.watch(selector, (element: any) => {
    import('@/modules/select').then((Select: any) => {
      new Select.default(
        element,
        element.dataset.options ? JSON.parse(element.dataset.options) : null,
      );
    });
  });
})();
